import http from '@/http/axios_init2'
// 上传题库
export function addSource(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/addSource',
		data
	})
}

// 题库列表
export function getSource(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/listSource',
		data
	})
}

//编辑题库
export function editSource(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/editSource',
		data
	})
}

//删除题库
export function delSource(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/delSource',
		data
	})
}
