/*
 * @Description: 配置
 * @Author: 琢磨先生
 * @Date: 2024-07-11 17:45:49
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-14 11:06:42
 */
export default {
	/**
	 * api请求host地址
	 */
	HOST:
		// process.env.NODE_ENV === 'development'
		// 	? 'http://222.174.167.26:9000'
		// 	: 'http://222.174.167.26:9000',

		process.env.NODE_ENV === 'development' ? 'http://dc-api.dcjy.top' : 'http://dc-api.dcjy.top',

	// process.env.NODE_ENV === 'development'
	// 	? 'http://222.174.167.26:3999'
	// 	: 'http://222.174.167.26:3999',

	HOST2: process.env.NODE_ENV === 'development' ? '/api' : 'https://dcedu.kmwl.top/api',
	// process.env.NODE_ENV === 'development' ? 'http://dc-api.dcjy.top' : 'http://dc-api.dcjy.top',
	/**
	 * 登录账号本次存储
	 */
	ACCOUNT_STORE_KEY: 'login_account',

	/**
	 * 本地缓存token名称
	 */
	TOKEN: 'emp.token',

	/**
	 * 本地缓存用户信息
	 */
	USERINFO: 'login_info',

	/**
	 * 项目app key
	 */
	APP_KEY: 'dc_admin',

	/**
	 * 平台 web pc
	 */
	APP_PLATFORM: 0,

	/**
	 * 本地缓存api key
	 */
	API_STORE_KEY: 'api_store',
	/**
	 * 文件下载的时候的域名替换直接访问域名 http://dcoaoss.yuandingcheng.com
	 */
	OSS_DOWNLOAD: 'https://dingchengoa.oss-cn-beijing.aliyuncs.com'
}
