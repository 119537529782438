import http from '@/http/axios_init2'

// 公职类型列表
export function getArea(data) {
	return http({
		method: 'post',
		url: 'XuelianApi/getArea',
		data
	})
}

// 公职类型列表
export function getStype(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/stype',
		data
	})
}
