/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-07-11 17:39:52
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-14 15:29:08
 */
import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import Layout from '@/layout'
import RouteLayout from '@/layout/layout2.vue'
import menus from '@/config/menus.js'
import { computed } from 'vue'

const routes = [
	{
		path: '/',
		name: 'login',
		component: () => import('@/views/login')
	},
	{
		path: '/pdfShow2',
		name: 'pdf',
		component: () => import('@/views/pdfShow2')
	}
]

menus.forEach((item) => {
	item.component = Layout
	routes.push(item)
})

const router = createRouter({
	history: createWebHistory(),
	routes: routes
})

/**
 * 前置守卫
 */
router.beforeEach(async (to, from, next) => {
	console.log(to, from)
	NProgress.start()
	next()
})

/**
 * 后置守卫
 */
router.afterEach(() => {
	NProgress.done()
})

export default router
